@import "../../theme.scss";

.table {
  display: table;
  height: 100%;
}

.cell {
  border: 2px solid black;
  vertical-align: top;
  display: table-cell;
  height: 100%;
}

.addFavorite {
  color: darkgrey;
}
.removeFavorite {
  color: $FAVORITE_STAR_BG;
}
.addFavorite,
.removeFavorite {
  text-shadow: rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px 1px 0px,
    rgb(0, 0, 0) 2px 2px 0px;
  z-index: 2;
}


.panel.links {
  display: inline-block;
  width: 100%;
  border: 0px !important;
  height: auto !important;
  background: transparent !important;
  margin: 0px;
  padding: 0px;
  text-align: center;
  // top:-20px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}
.links div.content {
  top: 0px;
  left: 0px;
  margin: 0px;
}


.wiki {
  width: 100%;
  display: inline-block;
  overflow: hidden !important;
}

.wiki .content {
  padding-top: 0px !important;
  top: 25px !important;
  padding-bottom: 10px;
}

.wiki .content * {
  font-size: 18px !important;
}
.spotlight{
  height:550px !important;
}

.panel.authorTwitter {
  flex-basis: 550px;
  width: 100% !important;
}
.panel.authorInstagram,
.panel.authorTwitter {
  background:rgba(0,0,0,0) !important;
  border: 0px;
}

.authorTwitter div.content {
  margin: auto;
}

.authorInstagram,
.authorPinterest{
  width: 100% !important;
  height: auto;
}

.authorPinterest.expanded div.content{
  width: 100% !important;
  padding-top: 100px;
  z-index: 5;
  position: fixed;
  left: 0px !important;
  top: 0px !important;
  background-image: radial-gradient(circle, yellow, orange);

  height: 100% !important;
  overflow-y: scroll;
}
.geekiImgDiv{
  min-width: 100px !important;
  min-height: 100px !important;
  float: left;
  margin-right:5px;
}
IMG.geekiImg{
  border-radius: 5px;

  float: left;
}
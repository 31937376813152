#bubbles {
    position: fixed;
    top: 0px;
    right: 400px;
    z-index: 300;
    width: calc(100% - 400px);
    height: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#bubbles.hide {
    display: none;
}

.cryptoFearAndGreedDiv{
    position: fixed;
    bottom:0px;
}

.bubblesHeader {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100%;
    background: darkgrey;
    width: 400px;
}

.bubblesHeader * .ttcButton{
    margin-top:100px;
    margin-bottom:100px;
    width:200px !important;
    font-size: 20px;
    margin-left: calc(50% - 100px);
    margin-right: calc(50% - 100px);
}
.bubblesHeaderBox{
    width:100%;
    font-size:16px;
    text-align:center;
}
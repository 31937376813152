@media (max-width: 400px) {
  .newsItem-img{
    max-width: 250px !important;
    min-width: 100% !important;
  }
}

.newsWidget{
  border-radius: 25px !important;
}

.newsWidget .content {
  width: 100% !important;
  top: 0px !important;
  padding-top: 25px !important;
}

.modernCC-widget-content {
  width: 100%;
  font-size: 18px;
  padding: 5px;
}
.newsItem-content {
  background: rgb(240, 240, 240);
}

.presentation .modernCC-widget-content {
  background: rgb(230, 230, 230) !important;
}

.presentation  * .carouselBg{
  background: rgb(35, 94, 111) !important;
  
}

.newsItem-title {
  color: rgb(35,  111, 94) ;
  font-weight: bold;
  width:100%;
}


.newsItem-title-date{
  float:right;
  color:lightgrey;
}

.newsItem-description {
  width: 100% auto !important;
  display: inline-block;
  margin-right: 10px;
}

.newsItem-description > div {
  width: 100% !important;
  height: auto;
  overflow-wrap: break-word !important;
  white-space: normal;
}
.newsItem-img {
  padding: 5px;
  height: auto !important;
  display: inline-block;
  background: rgb(35, 94, 111)  !important;
  border: 10px solid rgb(35, 94, 111) ;
}



  .geekiLayoutContent{
    margin-left:230px;
    width: calc(100% - 220px);
  }

  .geekiLogo{
    z-index:1001 !important;
  }


A.admSidebarLink{
    font-weight: bold !important;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor:pointer;
    background: transparent;
    outline: none;
    border: none;
  color: darkgray !important;
    padding: 7px 15px;
    margin: 10px 0px;
    border-radius: 0px;
    transition: all 0.3s ease;
  }
  
  .admSidebarLink:hover {
    background-color: rgb(15, 74, 91) !important;
    color: white !important;
  }
  
  .admSidebarLink:hover span{
    color: white !important;
  }
  
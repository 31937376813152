.geekiText {
  color: black;
  padding: 5px;
  font-weight: 500 !important;
  font-size: 18px;
  text-align: justify;
  padding-right: 5px;
}



.homeMenu {
  background: rgb(35, 94, 111);
  z-index: 1;
}
.homeMenu a {
  color: #e6e6e6 !important;
  margin: 5px !important;
  width: 15%;
}
.homeMenu a:hover {
  color: rgb(65, 124, 141) !important;
  background: orange !important;
}
.homeMenu a:hover i.icon {
  color: rgb(65, 124, 141) !important;
}
.homeMenuItem {
  width: 100%;
  margin: 5px;
  padding: 5px;
}

.homeMenuItem i.icon {
  font-size: 24px;
  color: #e6e6e6 !important;
}

.homeMenuItemTitle {
  padding-left: 5px;
  color: rgb(193, 179, 170) !important;
}

@import url("https://fonts.googleapis.com/css?family=Bangers&display=swap");
@import "./common.scss";
@import "./media.scss";
@import "./theme.scss";

@media (max-width: 400px) {
  .geekiLogo IMG{
    max-width:80px !important;
  }
}

[data-theme="lightMode"] {
  A {
    color: rgb(35, 94, 111) !important;
  }
  

  BODY,
  #root,
  #root2 {
    background: rgb(235,235,235) !important;
    /*
    background: rgb(205, 205, 205) !important;*/
  }

  .geekiLogo{
    background:white !important;
  }
}

[data-theme="darkMode"] {
  A {
    color: rgb(135, 194, 201) !important;
  }
  
  BODY,
  #root,
  #root2 {  
    background: rgb(50,50,50) !important;
  }
  .geekiLogo{
    background:rgb(50,50,50)  !important;
  }
}

body {
  margin: 0;
  font-family: Helvetica, Arial, Helvetica;
  width: 100%;
}

.withScrollerCarousel * .css-1l7c0cy{
  display: flex;
    justify-content: center;
    align-items: center;
}

.MuiBox-root.css-19midj6{
padding:0px;
}


i.green.icon {
  color: #019a25 !important;
}

* {
  font-family: Helvetica, Arial, Helvetica;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:after,
  &::before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

A {
  font-weight: bold;
}

.css-1jdx5mu{
  padding:0px !important;
  background: transparent !important;
}

.modernCC.pageNotFound {
  height: 200px !important;
  padding-bottom: 100px;
}
.sponsorBoxedImage {
  border-radius:4px;
}
.boxedImage {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  display: block;
  border-radius:4px;
}

.siteComment {
  background: transparent;
  border-radius: 5px;
  color: black;
  padding: 5px;
  font-weight: 500 !important;
  font-size: 18px;
  text-align: justify;
  padding-right: 5px;
}


.text.top-left {
  border-top: 0px;
  border-left: 0px;
}
.text.top-left span i {
  color: darkgrey;
}
#login-page {
  background-image: $CELL_RADIAL_2;
}

#login-page * label {
  color: white;
  font-weight: bold;
}

#login-page div.modal-content {
  background: transparent;
}



#login-title {
  color: white;
}





#root > div {
  height: 100% !important;
  width: 100% !important;
}

.container-fluid {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.app-container {
  padding-top: 0px;
  box-sizing: border-box;
  background: transparent !important;
  width: 100% !important;
  .view-container {
    width: 100% !important;
    margin-top: 140px;
    height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
    .card {
      padding: 0px;
      background:transparent !important;
      height: 100% !important;
    }
    .view-routes {
      height: 100% !important;
      display: flex;
      align-items: stretch;
    }
  }
}

.card{
  border: 0px !important;
}

.view-routes > div:first-of-type {
  width: 100%;
}

.fullscreen {
  position: fixed;
  top: 100px;
  left: 0px;
  width: 99% !important;
  height: calc(100vh - 110px) !important;
  margin: 5px;
  z-index: 1000;
  padding: 5px 25px 50px 25px !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Custom button styles
========================================================================== */

.icon-button > .btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5rem;
  line-height: 1rem;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.link:hover {
  color: orange !important;
}

/* ==========================================================================
Generic styles
========================================================================== */

.invalid-feedback {
  display: inline;
  font-size: 14px;
}

/* other generic styles */

.title {
  font-size: 1.25em;
  margin: 1px 10px 1px 10px;
}

.description {
  font-size: 0.9em;
  margin: 1px 10px 1px 10px;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
}

.error {
  color: white;
  background-color: red;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.break-word {
  white-space: normal;
  word-break: keep-all;
}

.preserve-space {
  white-space: pre-wrap;
}

/* padding helpers */

@mixin pad($size, $side) {
  @if $size== "" {
    @if $side== "" {
      .pad {
        padding: 10px !important;
      }
    } @else {
      .pad {
        padding-#{$side}: 10px !important;
      }
    }
  } @else {
    @if $side== "" {
      .pad-#{$size} {
        padding: #{$size}px !important;
      }
    } @else {
      .pad-#{$side}-#{$size} {
        padding-#{$side}: #{$size}px !important;
      }
    }
  }
}

@include pad("", "");
@include pad("2", "");
@include pad("3", "");
@include pad("5", "");
@include pad("10", "");
@include pad("20", "");
@include pad("25", "");
@include pad("30", "");
@include pad("50", "");
@include pad("75", "");
@include pad("100", "");
@include pad("4", "top");
@include pad("5", "top");
@include pad("10", "top");
@include pad("20", "top");
@include pad("25", "top");
@include pad("30", "top");
@include pad("50", "top");
@include pad("75", "top");
@include pad("100", "top");
@include pad("4", "bottom");
@include pad("5", "bottom");
@include pad("10", "bottom");
@include pad("20", "bottom");
@include pad("25", "bottom");
@include pad("30", "bottom");
@include pad("50", "bottom");
@include pad("75", "bottom");
@include pad("100", "bottom");
@include pad("5", "right");
@include pad("10", "right");
@include pad("20", "right");
@include pad("25", "right");
@include pad("30", "right");
@include pad("50", "right");
@include pad("75", "right");
@include pad("100", "right");
@include pad("5", "left");
@include pad("10", "left");
@include pad("20", "left");
@include pad("25", "left");
@include pad("30", "left");
@include pad("50", "left");
@include pad("75", "left");
@include pad("100", "left");
@mixin no-padding($side) {
  @if $side== "all" {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

/* end of padding helpers */

.no-margin {
  margin: 0px;
}

@mixin voffset($size) {
  @if $size== "" {
    .voffset {
      margin-top: 2px !important;
    }
  } @else {
    .voffset-#{$size} {
      margin-top: #{$size}px !important;
    }
  }
}

@include voffset("");
@include voffset("5");
@include voffset("10");
@include voffset("15");
@include voffset("30");
@include voffset("40");
@include voffset("60");
@include voffset("80");
@include voffset("100");
@include voffset("150");
.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a {
  color: $LINK !important;
}
a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

button.anchor-btn {
  background: none;
  border: none;
  padding: 0;
  align-items: initial;
  text-align: initial;
  width: 100%;
}

a.anchor-btn:hover {
  text-decoration: none;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */

#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

.width-min {
  width: 1% !important;
}

// Fix checkbox alignment 
.form-check-input {
  margin-top: -0.5rem;
}

.jh-card {
  height: 100%;
}

#app-view-container {
  margin-top: 0px;
  height: 100%;
  z-index: 2000;
}

::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}
::-webkit-scrollbar-thumb {
  background: #b3afb3;
  border: 1px dotted #525252;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b3afb3;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

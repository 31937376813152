 
  .sidebar {
  width: 80px;
  left: 0px;

  height: 100%;
  background-color: #333;
  position: fixed;
  top: 0;
  transition: left 0.3s ease-in-out;
  overflow-x: hidden;
  z-index: 1001 !important;
}

.sidebar.open {
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  left: 0px;
}

.sidebar.open * .toggle-button {
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.sidebar.open * .sidebarLink *I.large.icon {
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  margin-right: 30px !important;
}

.sidebar.open * .sidebar-menu {
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  text-align: center;
}

.sidebar * .iconWithSub DIV {
  text-align: right !important;
  margin-right: 40px !important;
}

.sidebar * .iconWithSub {
  line-height: 40px !important;
}

.sidebar.open * .iconWithSub DIV {
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  text-align: center !important;
  margin-right: 0px !important;
}

.sidebar * .iconWithSub SPAN {
  position: relative;
  top: -20px;
}


.toggle-button {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1003;
}

.sidebar-content {
  padding: 0px;
  color: #fff;
}

.sidebar-menu {
  padding-top: 120px;
  text-align: right;
}


.sidebarLink {
  font-weight: bold !important;
  text-transform: capitalize;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  color: darkgray !important;
  padding: 7px 0px;
  margin: 10px 0px;
  transition: all 0.3s ease;
}

.sidebarLink *I.large.icon {
  line-height: 0 !important;
  margin-right: 0px !important;
}

.sidebarLink *I.icon:before {
  font-size: 40px !important;
}

.sidebarLink div {
  text-align: center;
}


.sidebarLink:hover span {
  color: white !important;
}

.darkModeIcon {
  position: absolute;
  left: 10px;
  bottom: 0px;
}


.sidebarLink.agenda * SPAN.iconWithSub DIV {
  margin-left: 20px !important;
}

.sidebarLink.agenda * SPAN.iconWithSub SPAN {
  margin-right: 5px !important;
}

.sidebarLink.geeki * SPAN.iconWithSub DIV {
  margin-left: 20px !important;
}

.sidebarLink.geeki * SPAN.iconWithSub SPAN {
  left: 5px !important;
}

.sidebarLink.news * SPAN.iconWithSub SPAN {
  margin-right: 10px !important;
}

.sidebarLink.home * SPAN.iconWithSub SPAN {
  margin-right: 5px !important;
}
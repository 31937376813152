@import "./theme.scss";
.centered-out {
  width: 100%;
  text-align: center;
}

.centered-in {
  display: inline-block;
  margin: 0 auto;
  padding: 3px;
}

.inlineBlock {
  display: inline-block;
}

.stretchParent {
  display: flex;
  align-items: stretch;
}

.stretchParentWrap {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.stretchChild {
  display: inline-block !important;
  height: 100%;
}

.textShadow {
  text-shadow: rgb(0, 0, 0) -1px -1px 0px, rgb(0, 0, 0) 1px 1px 0px,
    rgb(0, 0, 0) 2px 2px 0px !important;
}

.width100Perc {
  width: 100%;
}
.widthAuto {
  width: auto !important;
}
.width100Perc-fullPage{
  margin-left: 0px;
}

.width100Perc-fullPage > div.panel {
  overflow: hidden;
}




.overflowHidden {
  overflow: hidden !important;
}

.overflowHidden > div.content,
//.overflowHidden > .modernCC-content
 {
  overflow: hidden !important;
}

.height100Perc {
  height: 100% !important;
}

.heightAuto {
  height: auto !important;
}
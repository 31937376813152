.rowTargets-container {
    width: 95%;
    display: flex;
    justify-content: space-between;
    height:200px;
  }

  .viewMode * .rowTargets-container {
    min-height:150px;
  }

  .rowTargets-container BUTTON.rowTargets-container-inside{
    height:100% !important;
  }
  .rowTargets-container .removeTarget BUTTON{
    height:30px !important;
    }

  .rowTargets-container.show BUTTON.rowTargets-container-inside.ttcButton2, .actionButton{
    background:rgba(0, 0, 0, 0.34);
    color:white !important;
    height:auto !important;
    border-radius: 10px;
    border: 2px rgb(100,100,100) solid;
  }
  .rowTargets-container.show BUTTON:hover.rowTargets-container-inside.ttcButton2:hover, .actionButton:hover{
    color:black !important;
    background:rgb(247, 166, 0);
  }

  .rowTargets-container.hide BUTTON.rowTargets-container-inside{
    background: transparent !important;
    border:1px transparent !important;
    color: transparent !important;
  }
  
  .rowTarget-container {
    display: flex;
    overflow: hidden;
    width:100%;

  }

  .summaryPercNotFull{
    background:#F9BAB3;
  }

  .percTarget{
    position:absolute;
    bottom:10px;
    left:40%;
    color:grey;
  }
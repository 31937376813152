/*, cell-radial-2: radial-gradient(circle, lightblue, deepskyblue)
#038ecf, #235E6F
*/

//cell-radial-2: radial-gradient(circle, #235E6F, #038ecf),
$themes: (
  default: (
    bgmenu-background: #235e6f,
    panel-background: white,
    //panel-background: rgb(220,220,220),
      //      panel-background: rgb(240, 240, 240),
      //panel-background: rgb(220, 220, 220),
      titleNoLink: orange,
    link: #4183c4,
    tab-list: rgb(35, 94, 111),
    tab-list-tab: orange,
    comics-page-title: #235e6f,
    comics-button: #fbc638,
    comics-button-hover: rgb(255, 99, 71),
    //background-body: rgb(240, 240, 240),
      background-body: rgb(50, 50, 50),
    radial-gray: radial-gradient(circle, rgb(150, 150, 150), rgb(90, 90, 90)),
    radial-orange: radial-gradient(circle, rgb(255, 165, 0), rgb(255, 190, 0)),
    gold: gold,
    img-box-shadow1: rgb(0, 0, 0) -1px -1px 0px,
    img-box-shadow2: rgb(0, 0, 0) 1px 1px 0px,
    img-box-shadow3: rgb(0, 0, 0) 2px 2px 0px,
    cell-radial-1: radial-gradient(circle, yellow, orange),
    cell-radial-2: radial-gradient(circle, #038ecf, #235e6f),
    cell-radial-3: radial-gradient(circle, palegreen, yellowgreen),
    cell-radial-4: radial-gradient(circle, lightcoral, tomato),
    page-border: black,
    social-disabled: lightgrey
  ),
  christmas: (
    bgmenu-background: #235e6f,
    panel-background: white,
    titleNoLink: white,
    link: darkgreen,
    tab-list: rgb(35, 94, 111),
    tab-list-tab: white,
    comics-page-title: #235e6f,
    comics-button: rgb(255, 99, 71),
    comics-button-hover: #fbc638,
    background-body: #0f8a5f,
    radial-gray: radial-gradient(circle, #cc231e, #f5624d),
    radial-orange: radial-gradient(circle, lightgreen, green),
    gold: #cc231e,
    img-box-shadow1: rgb(255, 255, 255) -1px -1px 0px,
    img-box-shadow2: rgb(255, 255, 255) 1px 1px 0px,
    img-box-shadow3: rgb(255, 255, 255) 2px 2px 0px,
    cell-radial-1: radial-gradient(circle, white, rgb(200, 200, 200)),
    cell-radial-2: radial-gradient(circle, lightblue, #0f8a5f),
    cell-radial-3: radial-gradient(circle, palegreen, yellowgreen),
    cell-radial-4: radial-gradient(circle, lightcoral, tomato),
    page-border: white,
    social-disabled: #d8f6f3
  ),
  halloween: (
    bgmenu-background: #236f5e,
    panel-background: rgba(255, 165, 0, 0.9),
    link: brown,
    titleNoLink: #fbc638,
    tab-list: rgb(35, 111, 94),
    tab-list-tab: orange,
    comics-page-title: #236f5f,
    comics-button: #fbc638,
    comics-button-hover: rgb(255, 99, 71),
    background-body: rgb(0, 0, 0),
    radial-gray: radial-gradient(circle, rgb(150, 150, 150), rgb(50, 50, 50)),
    radial-orange: radial-gradient(circle, rgb(255, 225, 0), orange),
    gold: gold,
    img-box-shadow1: rgb(0, 0, 0) -1px -1px 0px,
    img-box-shadow2: rgb(0, 0, 0) 1px 1px 0px,
    img-box-shadow3: rgb(0, 0, 0) 2px 2px 0px,
    cell-radial-1: radial-gradient(circle, yellow, orange),
    cell-radial-2: radial-gradient(circle, #03cf8e, #236f5e),
    cell-radial-3: radial-gradient(circle, palegreen, yellowgreen),
    cell-radial-4: radial-gradient(circle, lightcoral, tomato),
    page-border: black,
    social-disabled: lightgrey
  ),
  blackFriday: (
    bgmenu-background: gold,
    panel-background: lightgray,
    link: grey,
    titleNoLink: #fbc638,
    tab-list: grey,
    tab-list-tab: white,
    comics-page-title: darkgrey,
    comics-button: #fbc638,
    comics-button-hover: rgb(255, 99, 71),
    background-body: rgb(0, 0, 0),
    radial-gray: radial-gradient(circle, rgb(150, 150, 150), rgb(50, 50, 50)),
    radial-orange: radial-gradient(circle, rgb(255, 225, 0), orange),
    gold: gold,
    img-box-shadow1: rgb(0, 0, 0) -1px -1px 0px,
    img-box-shadow2: rgb(0, 0, 0) 1px 1px 0px,
    img-box-shadow3: rgb(0, 0, 0) 2px 2px 0px,
    cell-radial-1: radial-gradient(circle, yellow, orange),
    cell-radial-2: radial-gradient(circle, grey, darkgrey),
    cell-radial-3: radial-gradient(circle, palegreen, yellowgreen),
    cell-radial-4: radial-gradient(circle, lightcoral, tomato),
    page-border: black,
    social-disabled: lightgrey
  )
);
$mytheme: map-get($themes, default);
//$mytheme: map-get($themes, halloween);
//$mytheme: map-get($themes, christmas);
//$mytheme: map-get($themes, blackFriday);

$BMMENU_BACKGROUND: map-get($mytheme, bgmenu-background);
$PANEL_BACKGROUND: map-get($mytheme, panel-background);
$LINK: map-get($mytheme, link);
$TITLENOLINK: map-get($mytheme, titleNoLink);
$COMICS_PAGE_TITLE: map-get($mytheme, comics-page-title);
$TABLIST: map-get($mytheme, tab-list);
$TABLIST_TAB: map-get($mytheme, tab-list-tab);
$BODY_BG: map-get($mytheme, background-body);
$RADIAL_BG_GREY: map-get($mytheme, radial-gray);
$RADIAL_BG_ORANGE: map-get($mytheme, radial-orange);
$FAVORITE_STAR_BG: map-get($mytheme, gold);
$IMG_BOX_SHADOW: map-get($mytheme, img-box-shadow1),
  map-get($mytheme, img-box-shadow2), map-get($mytheme, img-box-shadow3);
$PAGE_BORDER: map-get($mytheme, page-border);
$CELL_RADIAL_1: map-get($mytheme, cell-radial-1);
$CELL_RADIAL_2: map-get($mytheme, cell-radial-2);
$CELL_RADIAL_3: map-get($mytheme, cell-radial-3);
$CELL_RADIAL_4: map-get($mytheme, cell-radial-4);
$SOCIAL_DISBLED: map-get($mytheme, social-disabled);
$COMICS_BUTTON_BG: map-get($mytheme, comics-button);
$COMICS_BUTTON_BG_HOVER: map-get($mytheme, comics-button-hover);
//menu-background-image:radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #235E6F 30%, #235E6F 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #235E6F 15%, #235E6F 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #235E6F 15%, #235E6F 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #235E6F 15%, #235E6F 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #235E6F 15%, #235E6F 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #038ecf 0, #51cbf1 30%, #7ff9ff 50%, #51cbf1 70%, #038ecf 100%) !important;
$MENU_BACKGROUND_SIZE: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100% !important;
$MENU_BACKGROUND_IMAGE: radial-gradient(
    ellipse farthest-corner,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 35%,
    #235e6f 30%,
    #235e6f 40%,
    rgba(0, 0, 0, 0) 90%
  ),
  radial-gradient(
    ellipse farthest-corner at 0px 0px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #235e6f 15%,
    #235e6f 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  radial-gradient(
    ellipse farthest-corner at 8px 8px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #235e6f 15%,
    #235e6f 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  radial-gradient(
    ellipse farthest-corner at 0px 8px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #235e6f 15%,
    #235e6f 20%,
    rgba(0, 0, 0, 0) 40%
  ),
  radial-gradient(
    ellipse farthest-corner at 8px 0px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #235e6f 15%,
    #235e6f 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  linear-gradient(
    40deg,
    #038ecf 0,
    #51cbf1 30%,
    #7ff9ff 50%,
    #51cbf1 70%,
    #038ecf 100%
  ) !important;
/*halloween: $MENU_BACKGROUND_IMAGE: radial-gradient(
    ellipse farthest-corner,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 35%,
    #236f5e 30%,
    #236f5e 40%,
    rgba(0, 0, 0, 0) 90%
  ),
  radial-gradient(
    ellipse farthest-corner at 0px 0px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #236f5e 15%,
    #236f5e 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  radial-gradient(
    ellipse farthest-corner at 8px 8px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #236f5e 15%,
    #236f5e 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  radial-gradient(
    ellipse farthest-corner at 0px 8px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #236f5e 15%,
    #236f5e 20%,
    rgba(0, 0, 0, 0) 40%
  ),
  radial-gradient(
    ellipse farthest-corner at 8px 0px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    #236f5e 15%,
    #236f5e 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  linear-gradient(
    40deg,
    black 0,
    #51f1cb 30%,
    black 50%,
    #51f1cb 70%,
    black 100%
  ) !important;
  */
/*blackfriday:*/
/*
$MENU_BACKGROUND_IMAGE: radial-gradient(
    ellipse farthest-corner,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 35%,
    gray 30%,
    gray 40%,
    rgba(0, 0, 0, 0) 90%
  ),
  radial-gradient(
    ellipse farthest-corner at 0px 0px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    gray 15%,
    gray 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  radial-gradient(
    ellipse farthest-corner at 8px 8px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    gray 15%,
    gray 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  radial-gradient(
    ellipse farthest-corner at 0px 8px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    gray 15%,
    gray 20%,
    rgba(0, 0, 0, 0) 40%
  ),
  radial-gradient(
    ellipse farthest-corner at 8px 0px,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    gray 15%,
    gray 20%,
    rgba(0, 0, 0, 0) 50%
  ),
  linear-gradient(40deg, black 0, gold 30%, black 50%, gold 70%, black 100%) !important;
  */

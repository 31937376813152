@media (max-width: 400px) {
  .geekiLayout-content {
    max-width: calc(100% - 85px);
  }
  .geekiLayout-header-title{
    font-size:20px !important;
  }
  .geekiLayout-header{
    background: rgb(35,  111, 94) !important;
    height:90px !important;
    padding-top:0px !important;
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    padding-left:0px !important;
  }
  .geekiLayout-content-inner{
    margin-top: 0px !important;
  }

  .geekiLayout-header-search{
    position: absolute !important;
    top: 90px !important;
    right: 0px !important;
    z-index:2000 !important;
  }
}
  .geekiLayout-container {
    display: flex;
    height: 100%;
  }
  .geekiLayout-component{
    min-height: calc(100% - 200px) !important;
  }

  .geekiLayout-sidebar {
    flex: 0 0 80px;
    transition: flex 0.3s ease-in-out; 
  }
  .geekiLayout-sidebar.open {
    flex: 0 0 200px;
  }

  .geekiLayout-content {
    flex: 1; 
  }
  .geekiLayout-content-inner{
    margin-left:0px !important;
    margin-top: 90px;
    width: 100% !important;
  }

  .geekiLayout-header{
      background: rgb(35,  111, 94) !important;
      height:90px;
      width:100%;
      color: white;
      text-align:left;
      font-size:30px;
      padding-top:30px;
      position: fixed;
      top: 0px !important;
      left: 0px !important;
      border: 0px;
      padding-left:230px;
      margin-bottom: 0px !important;
      width: 100%;
      z-index:1000 !important;
    }

    .geekiLayout-header-search{
      float: right;
      top: 0px !important;
      right: 0px !important;
      z-index:2000 !important;
    }
    .geekiLayout-header-title{
      font-size:70px;
      text-transform:uppercase !important;
      font-family: sans-serif !important;
      font-weight:bold;
      margin-left:10px;
    }
@import "../../../theme.scss";

.scrollMenuBeginEnd {
  width: 100% !important;
}
.scrollMenuBeginEnd .step.backward {
  position: absolute;
  left: 0;
  z-index: 2;
}
.scrollMenuBeginEnd .step.forward {
  position: absolute;
  right: 0;
  z-index: 2;
}
.scrollMenu {
  width: 100%;
  margin: auto !important;
  position: relative;
  bottom: 0px !important;
  /*top: -40px;*/
}

.playlists div.comicsCellFullWidth {
  margin: 10px;
}
.playlists div.comicsCellFullWidth DIV.panel{
  margin: 0px;
}
.playlist {
  display: inline-flex;
  margin: 5px;
  flex-basis: 420px;
  width: 420px !important;
  border: solid 2px grey;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
  border-radius: 2px;
}


.playlists * .text.top-left {
background:white !important;
margin: 0px;
}
.playlists * .text.top-left span i {
  color: black !important;
}

.playlists > DIV{
  background: rgb(35, 94, 111) !important;
  padding:0px;
}

.playlistContainer {
  /*background: #69a4b5 !important;*/
  margin: 0px;
  padding: 0px;
}

.playlistContainer1,
.playlistContainer2 {
  min-height: 20px;
  width: 330px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: initial;
}
.playlistContainer1 {
  position: absolute;
  margin-left: 0px;
  color: black;
  background-color: white;
}

.itemoftheday .playlistContainer1 {
  top: 200px;
  margin-left: 10px;
}
.playlistContainer2 {
  font-size: 12px !important;
  position: relative;
  bottom: -60px;
  color: lightgrey;
}

.playlist-content .comic {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.playlistComicsCell {
  min-height: 190px;
  height: auto !important;
  flex-basis: 350px;
  padding: 0px !important;
}

.playlistComicsCell > .content {
  width: 100% !important;
  top: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 0px !important;
}
.productOfTheDay {
  height: 450px;
}

.productOfTheDay div {
  margin: auto !important;
  text-align: center;
}

////////////////TV /////////////////////////
.ytcontainertop {
  z-index: 5;
}
#ytcontainer {
  max-width: 1024px;
  z-index: 6;
  position: fixed;
  top: 215px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ytOffButton {
  z-index: 7;
  position: absolute;
  top: 0px;
  right: 10px;
  color: red;
  float: right;
  font-size: 12px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.ytPreview {
  background:transparent;
  border: 0px;
  &:hover {
    cursor: pointer;
  }
}

.ytPreview.expanded img {
  filter: sepia(100%);
  filter: greyscale(100%);
  opacity: 0.3;
}

.playlistContainer.expanded {
  /* background-image: radial-gradient(circle, #038ecf, #235e6f);*/
  z-index: 1001;
}

.ytPlaylistPreview {
  z-index: 1001;
  position: fixed;
  left: 0px !important;
  bottom: 0px !important;
  width: 100%;
  background:rgba(35, 111, 94,0.7);
  height: 280px !important;
  display: none;
}

.ytPlaylistPreviewButtons,
.ytPlaylistPreviewScroller {
  display: none;
}
#ytPlaylistPreviewScroller:hover{
  z-index:6;
}

.playlistContainer.expanded div.ytPlaylistPreview div.ytPlaylistPreviewButtons {
  height: 280px !important;
  display: inline !important;
}

.playlistContainer.expanded
  div.ytPlaylistPreview
  div.ytPlaylistPreviewScroller {
  z-index: 5 !important;
  height: 280px !important;
  display: inline-block !important;
  width: 100%;
}

.scroll-menu-arrow .link {
  color: rgb(255, 165, 0);
}
.scroll-menu-arrow.scroll-menu-arrow--disabled .link {
  color: lightgrey;
  cursor: default;
}
.scroll-menu-arrow.scroll-menu-arrow--disabled:hover .link {
  color: lightgrey !important;
  cursor: default !important;
}

.ytPlaylistPreviewScrollerClose {
  font-family: "Bangers" !important;
  color: yellow;
  font-size: 20px !important;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  position: fixed;
  top: 80px;
  right: 50px;
  z-index: 7;
}

.ytPlaylistPreviewScrollerClose:hover {
  color: orange;
  text-shadow: -1px -1px orange, 1px 1px #ff0;
  cursor: pointer;
}

/////////////tmp

/* package default styles */

.component-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.component-slider .slider-container {
  margin: 0 30px;
  overflow: hidden;
}

.component-slider .slider-content {
  display: inline-block;
  transition: margin-left 0.15s ease-in;
}

.component-slider .caret {
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  color: #797979;
}

.component-slider .caret-right {
  right: 8px;
}

.component-slider .caret-left {
  left: 0px;
}

/*custom styles*/

.menu-bar {
  width: 100%;
  margin: auto !important;
  position: absolute;
  bottom: 0px !important;
  height: 350px !important;
}

.menu-item-wrapper {
  vertical-align: top;
}

.menu-item {
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
}
.menu-item .ytPreview .ytPlaylistItemPreview {
  height: 300px;
}

.react-horizontal-scrolling-menu--item,
.react-horizontal-scrolling-menu--separator,
.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right{//for youtube scroller imported component
  display:inline-block !important;
}

/////////////fin tmp

.playlistContainer.expanded div.ytPlaylistPreview {
  height: 100% !important;
  display: block !important;
  z-index: 1001;
}

.ytPlaylistItemPreview {
  display: inline-block !important;
  border: 2px black solid;
  /*background-image: radial-gradient(circle, yellow, orange);*/
  background: rgb(35, 94, 111) !important;
  margin: 5px;
  padding: 5px;
}

#monitor {
  background: #000;
  position: relative;
  border-top: 3px solid #888;
  margin: 5%;
  padding: 2% 2% 4% 2%;
  border-radius: 10px;
  border-bottom-left-radius: 50% 2%;
  border-bottom-right-radius: 50% 2%;
  transition: margin-right 1s;
  z-index: 5;
}

#monitor:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 3%;
  left: 36%;
  height: 0.5%;
  width: 28%;
  background: #ddd;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 white;
}

#monitorscreen {
  top: -5px;
  position: relative;
  background-color: #777;
  background-size: cover;
  background-position: top center;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

@media all and (min-width: 960px) {
  #monitor {
    -webkit-animation: tvflicker 0.2s infinite alternate;
    -moz-animation: tvflicker 0.5s infinite alternate;
    -o-animation: tvflicker 0.5s infinite alternate;
    animation: tvflicker 0.5s infinite alternate;
  }
  @-webkit-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(200, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 95px 0 rgba(200, 230, 255, 0.45);
    }
  }
  @-moz-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
  @-o-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
  @keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
}

.videoCount {
  border: 2px solid rgb(35, 94, 111);
  background: orange;
  position: relative;
  top: -75px;
  right: -330px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
}

.itemoftheday * .videoCount {
  display: none;
}

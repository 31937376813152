@media (max-width: 7000px) {
  .css-i9gxme {
    position: fixed;
    top: -2px;
    width: 100%;
    z-index: 9999;
  }

  .menu-item div.ytPlaylistItemPreview div.playlistContainer1,
  .menu-item div.ytPlaylistItemPreview div.playlistContainer2 {
    width: 320px;
  }
  .menu-item {
    width: 330px;
  }
  .playlist-content {
    width: 350px;
  }
  .width100Perc-fullPage {
    width: calc(100% - 20px) !important;
  }
  .modernCC.geekiPhoto.author {
    top: -58px !important;
  }
  .home {
    margin-top: 0px;
  }
  .homeMenu a {
    padding: 5px !important;
  }
  .homeMenuItem {
    font-size: 16px;
  }
  .headerMenu {
    margin-bottom: 40px !important;
    /*  position:fixed;*/
  }
  .newsItem-img {
    min-width: 300px;
    max-width: 300px;
  }
  .geekiSocials {
    width: 50px;
  }
  .vertical-timeline-element-icon i.icon.orange {
    font-size: 50px !important;
    margin-top: -7px;
    margin-left: 0px;
  }
  .authorTwitter {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  .authorTwitter div.content {
    width: 600px;
  }
  .loginButtonSpan {
    display: inline-block;
    width: 50%;
  }

  .pwdReset,
  .logout,
  #link-stats-heading,
  #user-management-page-heading,
  #playlist-heading {
    padding-top: 110px !important;
  }
  .headerMenu {
    left: 386px;
    width: calc(100% - 400px);

    position: fixed !important;
    z-index: 1000 !important;
    top: 0px;
  }
  .products {
    width: 100% !important;
  }
  .app-container {
    .view-container {
      padding: 1rem;
    }
  }
  .ui.menu.fixed.side-nav {
    width: 60px;
  }
  .univ0.universes,
  .univ1,
  .univ2,
  .univ3,
  .univ4,
  .univ5,
  .univ6,
  .univ7,
  .univ8,
  .univ9 {
    width: 240px !important;
  }
  .homeInfo {
    width: 200px;
  }
  .account-panel {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    width: 500px;
  }
  .about-panel,
  .logout-panel {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    width: 800px;
  }
  .loginInfo {
    width: 200px;
  }
  .geekiDescription,.timeline {
    display: flex;
    align-items: stretch;
  }
  #ytcontainer {
    right: 200px;
  }
  #universe7 {
    padding-left: 60px;
    padding-top: 30px;
  }
}

@media (max-width: 1081px) {
  .homeMenuItem {
    font-size: 12px !important;
  }
  .newsWidget {
    width: 100%;
  }

  .newsWidget .content {
    //old widget
    width: 250px;
    display: block;
  }

  .newsWidget .modernCC {
    display: block !important;
    background: #f0f0f0 !important;
  }
  .width100Perc-fullPage {
    margin-right: 30px;
    width: 100% !important;
  }
  .css-1t29gy6-MuiToolbar-root {
    padding-left: 0px;
  }
  .MuiIconButton-root {
    padding: 5px !important;
  }
  .newsItem-img {
    position: relative;
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (max-width: 767px) {
  .css-i9gxme {
    position: absolute;
    top: 0px;
  }
  .fPDpuU {
    margin: 0px !important;
    left: -50px !important;
  }
  .qoLPS {
    width: calc(100% + 100px) !important;
  }
  .rec-arrow-left {
    z-index: 2;
  }
  .rec-arrow-right {
    right: 100px !important;
    z-index: 2;
    position: relative;
  }

  .khvUfi {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    line-height: 20px !important;
  }
  .bVibPY {
    margin: 0px !important;
    width: 80% !important;
  }
  .home {
    margin-top: 0px;
  }
  .products {
    margin-top: 40px !important;
  }
  .headerMenu {
    margin-top: 00px !important;
    left: 0px;
    width: 100%;
    position: relative !important;
  }
  .about-panel,
  .logout-panel {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    wi
  .home.stretchParent {
    display: block;
  }
  .app-container {
    .view-container {
      padding: 0px;
    }
  }
  .ui.menu.fixed.side-nav {
    width: 40px;
  }
  .geekiPhoto {
    display: block !important;
  }
  .geekiPhoto.author {
    height: 500px !important;
  }

  .geekiPhoto.work {
    height: 200px !important;
  }

  .products {
    margin-left: -20px !important;
    margin-right: 5px !important;
    width: 100% !important;
  }
  .products,
  .register,
  .pwdReset,
  .about {
    margin-top: 50px;
  }

  .productOfTheDay div {
    width: 340px;
  }
   .univ0.universes,
  .univ0,
  .univ1,
  .univ2,
  .univ3,
  .univ4,
  .univ5,
  .univ6,
  .univ7,
  .univ8,
  .univ9 {
    width: 150px !important;
    margin-left: 10px;
  }
  .univ0.panel {
    display: block !important;
  }
  .homeInfo {
    width: 150px;
    margin-left: 10px;
  }
  .account-panel,
  .about-panel,
  .logout-panel {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    width: 300px;
  }
  .loginInfo {
    width: 150px;
  }
  #ytcontainer {
    right: 200px;
  }
}

@media (max-width: 481px) {
  .menu-item {
    width: auto;
  }

  .width100Perc-fullPage {
    margin-right: 0px;
    width: 100% !important;
  }
  .modernCC {
    display: block !important;
  }
  .modernCC-widget-content {
    display: block !important;
    /*  left: -20px;*/
    position: relative;
    /*    width: 300px !important;*/
    width: 100% !important;
  }
  .homeMenu {
    display: inline-block !important;
  }
  .homeMenu a {
    display: inline !important;
    padding: 10px !important;
    background: rgb(65, 124, 141);
    border: 1px solid black;
  }
  .homeMenuItem {
    /*display: inline-block !important;*/
    display: none !important;
  }
  .headerMenu {
    margin-bottom: 20px !important;
  }
  .newsWidget {
    width: 300px;
  }

  .newsWidget .content {
    //old widget
    width: 250px;
    display: block;
  }
  .geekiSocials {
    width: 100%;
  }
  .vertical-timeline-element-date .productWidget {
    display: none;
  }
  .vertical-timeline-element-icon i.icon.orange {
    font-size: 40px !important;
    margin-top: -19px;
    margin-left: -10px;
  }
  .authorTwitter div.content {
    width: 300px;
  }
  .twitchContentInner {
    top: 0px !important;
    left: 10px !important;
    padding-top: 40px !important;
    width: 90% !important;
  }
  h1 {
    margin-top: 3px !important;
    line-height: 30px !important;
  }
  #ytcontainer {
    left: auto !important;
    right: auto !important;
    transform: none !important;
    top: 50px !important;
  }
  .menu-item div.ytPlaylistItemPreview div.playlistContainer1,
  .menu-item div.ytPlaylistItemPreview div.playlistContainer2 {
    width: 240px !important;
  }

  .playlistContainer1 {
    min-height: 14px !important;
    font-size: 11px !important;
  }
  .playlistContainer2 {
    min-height: 12px !important;
    font-size: 11px !important;
  }
  .ytPlaylistItemPreview {
    margin: 1px !important;
  }
  .ytPlaylistPreviewScrollerClose {
    top: 5px !important;
    right: 10px !important;
  }
  .component-slider .slider-container {
    margin: 0 0px !important;
  }
  h2 p {
    font-size: 18px !important;
  }
  .comicsPage {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .playlist-content {
    /*width: 300px !important;*/
    width: 100% !important;
  }
  .playlistComicsCell {
    flex-basis: 300px !important;
  }
  .wiki .content {
    padding-bottom: 50px !important;
  }
  .panel.links {
    padding: 0px !important;
  }
  .loginButtonSpan {
    display: block !important;
    padding-bottom: 10px;
  }
  .products {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    padding-top: 20px !important;
  }
  .panel {
    margin: 0px !important;
  }
  .panel.univ2,
  .panel.univ0.universes {
    margin: 1px !important;
  }
  .geekiDescription,.timeline {
    min-height: 100px !important;
  }
  .socials-diff {
    width: 100% !important;
  }
  .sidebar-item-alignment-container-collapsable-item {
    margin-bottom: 0px !important;
    padding-bottom: 5px !important;
  }
  .sidebar-item-alignment-container-collapsable-item * i.large.icon,
  .sidebar-item-alignment-container * i.large.icon{
    font-size: 16px !important;
  }
  .ui.search {
    left: -20px;
  }

  .ui.menu .item {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    line-height: 26px !important;
  }
  .geekiDescription,.timeline {
    display: block;
  }
  .homeInfo {
    margin-left: 2px !important;
  }

  .ui.menu .item {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    line-height: 26px !important;
  }
  .geekiDescription,.timeline {
    display: block;
  }
  .geekiText {
    font-size: 20px !important;
    width: 300px !important;
    /*   padding-top: 200px !important;*/
  }
  #universe3 {
    padding-left: 40px !important;
    padding-top: 40px !important;
  }
  #universe7 {
    padding-left: 20px !important;
    padding-top: 0px !important;
  }
}
}
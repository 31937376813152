.adminPage {
  background: rgb(193, 179, 170);
  position: fixed;
  bottom: 0%;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100% !important;
  padding: 10px;
  z-index: 3 !important;
}

.adminPage .table-responsive {
  width: 100%;
}

a.btn.admin {
  background-image: radial-gradient(circle, #ffe100, orange) !important;
  color: black;
  margin: 5px;
}

a.btn.admin,
.adminPage * ul.pagination * .page-link {
  background-image: radial-gradient(circle, #ffe100, orange) !important;
  color: black;
  margin: 5px;
}

.adminPage * .table th,
.adminPage * .table td {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.adminPage * input:disabled,
.adminPage * select:disabled {
  background-color: rgb(235, 235, 228);
  border-bottom-color: rgb(217, 217, 217);
}

.adminPage * input,
.adminPage * textarea {


  border-radius: 4px;

  color: rgb(51, 51, 51);

  font-family: OpenSansRegular, OpenSans, Helvetica;

  font-size: 14px;

  height: 28px;

  line-height: 28px;
}

.adminPage * textarea {
  color: rgb(51, 51, 51);
  border: rgb(206, 212, 218);
  font-family: OpenSansRegular, OpenSans, Helvetica;

  font-size: 14px;

  line-height: 28px;
  padding-left: 12px;

  padding-right: 28px;

  border-radius: 11px !important;
}

.adminTitle {
  padding: 10px 10px;
  height: 100%;
  width: 100%;
  margin-top: 3px !important;
}

.adminTitle1 {
  float: right;
  left: 200px;
}

.MuiAvatar-root {
  height: 120px !important;
  width: auto !important;
  float: left;
  border-radius: 0 !important;
  display: inline-flex !important;
  margin-right: 10px;
}

.adminTitle .MuiAvatar-root {
  display: inline-flex !important;
  float: right;
  height: 120px !important;
  width: auto !important;
  top: -10px;
  right: -11px;
  border-radius: 0 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: orange !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: rgb(35, 94, 111) !important;
}

.css-i4bv87-MuiSvgIcon-root {
  color: rgb(35, 94, 111) !important;
}

.form-control::placeholder {
  color: #d6d6d6 !important;
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  width: 20px;
}

.adminContent {
  display: flex;
  align-items: stretch;
  width: calc(100% - 20px);
  background: rgb(201, 194, 190) !important;
  margin: 10px;

}

.admLiquiVersionOk {
  color: green;
}

.admLiquiVersionNok {
  color: red;
}

.editorButton {
  margin-left: 5px;
}




  [data-theme="lightMode"] {
    .productWidget .title{
      background: white !important;
      color: black;
    }
  }
  [data-theme="darkMode"] {
    .productWidget .title{
      background:rgb(140,140,140)  !important;
      color: lightgrey;
    }

  }

.productCategories {
  margin:5px !important;
  margin-top: 25px;
}

.productCategories article.comicsPage{
  margin-top: 25px;
  padding-top: 40px !important;
}


.product {
  width: 120px;
  height: 240px;
  margin: 5px;
}

.productWidget{
  background: rgb(35, 94, 111) !important;
  display: inline-block !important;
  border: 1px solid black;
  width: 240px; //240
  height: 420px;
  margin: auto;
  text-align: center;
  position: relative;
  color: white;
}
.productWidget .title{
  left:0px;
  width:100%;
  margin: auto;
  padding-bottom: 10px;
  height: 70px;
  position: relative;
}
.productWidget .title SPAN{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.productWidget .middle{
  background:green !important;
}
.productWidget .bottom{
  bottom: 0px !important;
  position: absolute;
  width: 100%;
}

.productCategories .productWidget{
  margin:5px;
}
.productCategories * .comicsPage-title{
  background: rgb(95, 171, 154) !important;
  margin: 5px;
  padding-bottom: 5px !important;
}
.productCategories * .comicsPage-title SPAN I{
color: rgb(50,50,50);
}


.itemoftheday .modernCC-widget-content {
  background: rgb(193, 179, 170);
}
.modernCC * .productWidget {
  background: transparent !important;
  border: 0px solid rgba(0, 0, 0, 0) !important;
}
.productWidget .geekiImg {
  margin-left: 35px;
  margin-right: 35px;
}

.tv.comicsCellTitle.large.link.icon,
.shop.comicsCellTitle.large.link.icon {
  margin-top: 10px !important;
}



/*.authorTwitch {
    flex-basis: 550px;
    width: 100% !important;
    min-height: 600px;
}

.authorTwitch DIV.content {
    width: calc(100% - 10px);
    margin: auto;
    min-height: 500px;
    top: 80px;
}*/

.authorTwitch {
  width: 100% !important;
  height: auto;
}

.authorTwitch.expanded div.content {
  width: 100% !important;
  padding-top: 100px;
  z-index: 5;
  position: fixed;
  left: 0px !important;
  top: 0px !important;
  background-image: radial-gradient(circle, #8466bd, #6441a4);

  height: 100% !important;
  overflow-y: scroll;
}

.twitchContent {
  max-height: 50%;
}
.twitchContentInner {
  height: calc(100% - 250px);
  width: 80%;
  position: absolute;
  top: 0px;
  left: 100px;
  padding-top: 40px;
}

#monitorTwitch {
  width: auto;
  background: #000;
  position: relative;
  border-top: 3px solid #888;
  margin: 5%;
  padding: 2% 2% 4% 2%;
  border-radius: 10px;
  border-bottom-left-radius: 50% 2%;
  border-bottom-right-radius: 50% 2%;
  transition: margin-right 1s;
  z-index: 5;
}

#monitorTwitch:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 3%;
  left: 36%;
  height: 0.5%;
  width: 28%;
  background: #ddd;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 white;
}

#monitorTwitchscreen {
  top: -5px;
  width: auto;
  position: relative;
  background-color: #777;
  background-size: cover;
  background-position: top center;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

@media all and (min-width: 960px) {
  #monitorTwitch {
    -webkit-animation: tvflicker 0.2s infinite alternate;
    -moz-animation: tvflicker 0.5s infinite alternate;
    -o-animation: tvflicker 0.5s infinite alternate;
    animation: tvflicker 0.5s infinite alternate;
  }
  @-webkit-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(200, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 95px 0 rgba(200, 230, 255, 0.45);
    }
  }
  @-moz-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
  @-o-keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
  @keyframes tvflicker {
    0% {
      box-shadow: 0 0 100px 0 rgba(225, 235, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 60px 0 rgba(200, 220, 255, 0.6);
    }
  }
}

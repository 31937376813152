@media (max-width: 400px) {
    .footer{
        font-size:9px;
    }
}
.footer{
    background-color:darkgrey;
}
.footer-link{
    color:white !important;
    font-weight:bold;
}
.filter-box{
    background: rgb(4, 186, 146);
    color:black;
    padding:5px;
}

.filter-box * .form-control{
    margin:5px;
}
.filter-box * .form-control::placeholder{
  color:green !important;
    margin:5px;
}
.filter-box * .form-control FIELDSET{
    border: 1px solid rgba(0,0,0,0) !important;
}

.filter-box-main {
    background: rgb(240, 240, 240);
    margin:0px;
  }

.filter-expand{
    background: rgb(230, 230, 230) !important;
    padding:5px;
    width:100% !important;
}
.filter-expand-create{
  float:right;
}

.filter-box-main-content{
    border-radius: 25px !important;
  }
  
.filter-box-main-content .content {
    width: 100% !important;
    top: 0px !important;
    padding-top: 25px !important;
  }
  .filter-box-main-content.modernCC-content {
    width: 100%;
  }
  .filter-box-main-content.panel {
    background: transparent;
    border: 0px;
    box-shadow: 0 0px 0px 0px #000 !important;
  }
[data-theme="lightMode"] {

  .geekiInput * input{
    background:white ;
  }

}

[data-theme="darkMode"] {

  .geekiInput * input{
    background:darkgrey ;
    color: white;
  }

}

.geekiInput.ui.search * .prompt {
  border-radius: 4px !important;
  width: 100%;
}
.geekiInput * input {
  width: 220px;
  font-size: 12px;
}

.geekiInput{
  border:none;
  padding: 0px;
  width: 280px;
}

.geekiInput.ui.search > .results .result {
  line-height: 10px !important;
  padding: 5px !important;
}

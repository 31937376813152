[data-theme="lightMode"] {
  .timeline * .tlCard{
    background: rgb(215,215,215) !important;
  }
  .vertical-timeline-element-content.bounce-in{
    background:rgb(225,225,225)  !important;
  }
  .vertical-timeline-element--work.withScroller
  .vertical-timeline-element-content {
  background: rgb(181, 174, 170) !important;
}
  .timeline * .type,
.timeline * .year {
  color: black;
}
.timeline * .geekiLink a.link {
  color: rgb(35, 94, 111) !important;
}
.timeline * .geekiLink {
  color:rgb(50,50,50);
}
}
[data-theme="darkMode"] {
  .timeline * .tlCard{
    background: rgb(140,140,140) !important;
    color:lightgrey !important;
  }
  .vertical-timeline-element-content.bounce-in{
    background:rgb(120,120,120)  !important;
  }
  .vertical-timeline-element--work.withScroller
  .vertical-timeline-element-content {
  background: rgb(161, 154, 150) !important;
}
  .timeline * .type,
.timeline * .year {
  color: lightgrey !important;
}
.timeline * .geekiLink a.link {
  color: rgb(135, 194, 201) !important;
}
.timeline * .geekiLink {
  color: lightgrey !important;
}
}

@media only screen and (min-width:1170px){
  .vertical-timeline.vertical-timeline--two-columns{
    width:100% !important;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date{
    left: 120% !important;
  }
}

.horizontal-menu {
  margin: auto;
}

.activity * .wiki .content ul li,
.activity * .wiki .content ul li span,
.activity * .wiki .content ul li a,
.activity * .wiki .content p,
.activity * .wiki .content div {
  font-size: 14px !important;
}

.activity * .content ul {
  list-style-type: none;
}
.activity * .content ul span ul {
  list-style-type: square;
}
.vertical-timeline-element-icon i.icon {
  line-height: 75px !important;
}
.vertical-timeline-element-icon i.icon.orange {
  color: rgb(255, 165, 0) !important;
}


.vertical-timeline-element--work.withScroller
  .vertical-timeline-element-content {
  min-height: 570px !important;
  min-width:520px !important;
}
.withScrollerCarousel{
width:540px;
background-color:rgb(75, 134, 151) !important;
padding:21px;
}

.timeline .wiki .content {
  margin-bottom: 30px;
}

.timeline .panel.wiki {
  background: transparent !important;
  border: 0px;
}
.timeline * .productWidget.url {
  width: 500px !important;
  min-height:500px;
}
.timeline * .productWidget.url .panel{
  border:0px;
  background:transparent;
}
.productWidget * title {
  height: 90px;
  top: -20px;
}


.timeline .wiki .content * .geekiLink {
  font-size: 18px !important;
}

.timeline * .publisher{
  color:black;
}

.timeline .wiki .content * .publisher div,
.timeline .wiki .content * .type,
.timeline .wiki .content * .year {
  font-size: 14px !important;
}
.timeline * .comment{
  color:rgb(100,100,100);
}

.timeline * .tlCard{
  margin-bottom:10px;
  padding:5px;
}

.timeline * .geekiLink {
  font-weight: bold;
}

.timeline * .geekiLink a.link:hover {
  color: rgb(255, 165, 0) !important;
}

.vertical-timeline--two-columns
  .vertical-timeline-element:nth-child(2n)
  .vertical-timeline-element-content
  .vertical-timeline-element-date
  .productWidget {
  float: left;
}


.vertical-timeline--two-columns
  .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left)
  .vertical-timeline-element-content
  .vertical-timeline-element-date
  .productWidget {
  float: none !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  padding-top: 1em;
}

.vertical-timeline-element-content * UL{
  padding-left:0px;
}
.vertical-timeline-element-content.bounce-in{
  width:520px !important;
}

.vertical-timeline-element-icon i.icon.orange {
  width: 60px !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  padding: 0px !important;
}

.vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
  top: 0px !important;
}
.vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
  height: 100%;
}



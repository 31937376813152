@import "../../../theme.scss";


.footer * .panel.geekiSocials {
  float: right !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.footer * .panel.geekiSocials .modernCC-content {
  top: 40px !important;
}

.links {
  filter: grayscale(90%);
}

.links:hover {
  filter: grayscale(0%);
}

.links .modernCC-content {
  top: 25px;
  padding-bottom:25px;
}

.links * .modernCC {
  display: block;
  top: 0px;
}


.sidebar * DIV.social,
.footer * DIV.social,
.links * DIV.social {
  width: 30px;
  height: 30px;
}

.sidebar * DIV.social.pl,
.footer * DIV.social.pl,
.links * DIV.social.pl {
  width: 15px;
  height: 15px;
}

.sidebar * DIV.social * IMG,
.footer * DIV.social * IMG,
.links * DIV.social * IMG {
  width: 25px !important;
  height: 25px !important;
  margin-left: 0px;
  margin-top: 0px;
}


div.social,
span.social {
  display: inline-block;
  border-radius: 5px;
  border-left: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  margin: 5px;
}

div.social {
  width: 60px;
  height: 60px;
  top: 0px;
  vertical-align: top;
}

div.social.pl {
  width: 30px;
  height: 30px;
}

span.social {
  width: 34px;
  height: 34px;
  margin: 0px;
  padding: 0px;
}

div.social a {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 7px;
  position: relative;
  left: -4px;
}

div.social a img,
div.social img {
  margin-left: 4px;
  margin-top: 4px;
}

div.social.pl img {
  margin-left: 1px;
  margin-top: 1px;
}

div.social a img {
  margin-top: 5px !important;
}

.socialInput div {
  display: inline;
  position: absolute;
}

div.social.disabled {
  background: $SOCIAL_DISBLED !important;
}

div.social.disabled img {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

div.social.wiki a {
  padding: 0px;
}

.socialNumber {
  position: absolute;
  top: -11px;
  right: 2px;
  width: 10px;
  background: lightgrey;
  color: black;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 2px;
}

.socialCount {
  background-image: radial-gradient(circle, yellow, orange);
  z-index: 4;
  position: relative;
  top: -55px;
  right: -40px;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 10px;
}

.socialPopup {
  position: relative;
  top: -75px;
  right: 0px;
  z-index: 5;
  border: 1px solid black;
  display: block;
  min-height: 60px;
  min-width: 100px;
  background: orange;
  word-wrap: normal !important;
}

.socialPopup a {
  font-size: 10px !important;
  line-height: 16px;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  display: block;
}

.socialPopup a:hover {
  background: #4183c4;
  color: orange !important;
}

.xFollow{
  display: inline-block;
  width:150px;
  margin-top:9px;
}

.socials {
  display: inline-block;
  padding: 0px;
  margin-left: 0px;
}

.socials-diff {
  width: calc(100% - 50px);
}

.geekiSocials {
  display: block;
  padding: 0px;
  margin-left: 0px;
  filter: grayscale(90%);
}
.geekiSocials:hover{
  filter: grayscale(0%);
}

.geekiSocials .content {
  top: 25px !important;
}

.footer * .geekiSocials * .modernCC-widget{
  width: 100% !important;
}
.geekiSocialsInner{
  width: 100% !important;
  display: inline;

}

.twitter {
color: black !important;
background: white;
}

.twitter a {
  position: relative;
  left: -4px;
}

.facebook {
  background: #4172b8;
}

.wiki {
  background: #ffffff;
}

.instagram {
  background: #e4405f;
}

.twitch {
  background: #ffffff;
}

.pinterest {
  background: #bd081c;
}

.linkedin {
  background: #0077b5;
}

.rss {
  background: #ffa500;
}

.discord {
  background: #7289da;
}

.youtube {
  background: #ffffff;
}

.dailymotion {
  background: #ffffff;
}

.imdb {
  background: #e6b91e;
}

.tiktok {
  background: #000000;
}

.url {
  background: rgb(255, 165, 0);
}

.amazon {
  background: #ff9900;
}

.soundcloud {
  background: #ff3300;
}

.podcloud {
  background: #21218b;
}

.tipee {
  background: white;
}
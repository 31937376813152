@import "../../../theme.scss";
$header-color: #fff;
$header-color-secondary: #bbb;
$header-color-hover: darken($header-color, 20%);

.css-vubbuv {
  fill: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */

.ribbon {
  background-color: red;
  right: -3.5em;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  overflow: hidden;
  position: fixed;
  top: 30px;
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.75;
}
.ribbon button {
  color: white;
  background-color: red;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  padding: 10px 90px;
  text-align: right;
  text-decoration: none;
  text-shadow: 0 0 5px #ee5b5b;
  pointer-events: none;
  border:0px;
}



/* ==========================================================================
Navbar styles
========================================================================== */

li.nav-item a span span,
li.nav-item a span {
  color: black;
}

.loading-bar {
  height: 3px;
  background-color: #009cd8;
  position: absolute;
  top: 0px;
  z-index: 1031;
}

@import "../../theme.scss";

@media (max-width: 400px) {
  .geekiImg, .geekiImg IMG, .geekiImg A IMG{
    max-width: 250px !important;
  }
}

[data-theme="lightMode"] {
  .geekiWidget-title{  
    background-color:rgb(240,240,240) !important;
  }
  .geekiWidget-title.text.top-left span i{
    color: black;
  }
}

[data-theme="darkMode"] {

  .geekiFirstRow {  
    color: lightgrey;
  }
  .geekiWidget-title{  
    background: rgb(151, 144, 140) !important;
  }
  .geekiWidget-title.text.top-left span i{
    color: lightgrey;
  }
}

.registerequest {
  margin: auto !important;
  padding-bottom: 25px;
}

.hs {
  display: grid;
  grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: 10px;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: calc(50% - var(--gutter) * 2);

  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(0.75 * var(--gutter));
  margin-bottom: calc(-0.25 * var(--gutter));
}

.hs:before,
.hs:after {
  content: "";
  width: 10px;
}

.hs > li,
.itemA2 {
  scroll-snap-align: center;
  /*padding: calc(var(--gutter) / 2 * 1.5);*/
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-right: 20px;
}

.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.geekiImg {
  top: -20px;
  opacity: 1;
  filter: grayscale(0%);
  margin: auto;
}

.panel.modernCCPanel.geekiWidget{
  height: 240px  !important;
}

.geekiWidget{
  background-color:rgb(35, 94, 111) !important;/*
   background-color:rgb(153, 183, 192) !important;
  background-color:rgb(75, 134, 151) !important;*/
  display: inline-block !important;
  width: 265px !important;
  height: 240px;
  flex-basis: 400px;
}
.geekiWidget div.content {
  left: 0px !important;
  top: 5px !important;
  width: 100% !important;
  text-align: center !important;
  padding-top: 0px !important;
}
.geekiWidget * .geekiImg IMG{
  margin-left: 54px;
  margin-top: 40px;
  margin-right: 15px;
  position: relative;
  border-radius : 4px !important;
}

.geekiWidget * .geekiImg.AUTEUR IMG{
  margin-top: 20px;
  margin-left: 84px;
  left: -30px;
}
.geekiWidget:hover * img {
  opacity: 0.8;
  -webkit-filter: grayscale(50%);
  -moz-filter: grayscale(50%);
  -o-filter: grayscale(50%);
  -ms-filter: grayscale(50%);
  filter: grayscale(50%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.geekiWidget-title{
  left:0px;
  padding-bottom: 10px;
}

.geekiWidget * .geekiImg{
  top: 0px;
  margin-left:-58px;
//left: -125px;
}
.geekiWidget * .geekiImg.AUTEUR{
  margin-left:0px;
}
.geekiWidget-skills{
  background-color:rgb(55, 114, 131) !important;
  left:0px;
  bottom:0px;
  width:100%;
}

.geekiWidget-skills SPAN{
  color:white !important;
}


.geekisWidget * .tooltipvalidated {
  bottom: 0px !important;
}

.geekisWidget{
  padding-top: 10px;
  margin: auto !important;
  text-align: center;
}

.geekiDescription{
  text-align: justify;
}



.geekiPhotoInner{
  margin-left:0px !important;
  margin-right:20px !important;
  margin-top:0px !important;
}

.geekiFirstRow {
  display: flex !important;
  align-items: stretch;
  width: 100%;
  margin-bottom: 20px;
  display: block;
}
.iconWithSub{
  text-align: center;
}
.iconWithSub SPAN{
  color:lightgrey;
  font-size:12px;
}

.geekiNetwork{
  display: flex;
  flex-wrap: wrap;
 /* align-items: stretch;*/
}

.geekiNetwork > DIV.panel.comicsCellTitle{
  background: transparent !important;
  border: 0px;
}
@import "../../theme.scss";

@media (max-width: 400px) {
  .convention-description IMG.geekiImg{
    max-width: 250px !important;
  }
}



.convention{
  background: rgb(240,240,240) !important;
}

.convention-date{
    float:left;
    background-color:rgb(35, 94, 111);
    color:lightgrey;
    width:80px;
    border: 1px solid darkgrey;
    text-align: center;
    font-size:16px;
    font-weight:bold;
    margin-right:10px !important;
  }
  .convention-date-year{
  }
  .convention-date-month{
  }
  .convention-date-day{
    font-size:22px;
  }
  .convention-title {
    background-color:rgb(75, 134, 151) !important;
    color:lightgrey;
    font-weight: bold;
    width:100%;
    min-height:62px;
  }

  .convention-description {
    width: 100% auto !important;
    display: inline-block;
  }
  
  .convention-description > div {
    width: 100% !important;
    height: auto;
    overflow-wrap: break-word !important;
    white-space: normal;
  }
  .convention-postalCode SPAN{
    font-weight:bold;
  }
  .convention-city SPAN{
    font-weight:bold;
  }
  .convention-address SPAN{
    font-weight:bold;
  }
  .convention-country SPAN{
    font-weight:bold;
  }
  .convention-web SPAN{
    font-weight:bold;
  }
  .convention-geeki SPAN{
    font-weight:bold;
  }

  .convention-proposal-content {
    margin-top: 0px;
    width: 95%;
  }
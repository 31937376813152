@import "../../../theme.scss";

I.comment.circle.commentbox.icon:before{
    color:white !important;
}

DIV.commentbox{
    background:rgba(0,0,0,0.05);
    border:rgba(0,0,0,0.2) 1px solid;
    margin:5px;
    padding:5px;
}
.cryptoFiltered{
    display:none !important;
}

.ttcButton {
    background: rgb(247, 166, 0);
    border-radius: 4px;
    border: 2px rgb(100, 100, 100) solid;
    color:black;
}

.ttcButton:hover {
    border: 2px orange solid;
    cursor:pointer;
}

.ttcButton2 {
    background: rgba(0, 0, 0, 0.54);
    border-radius: 10px;
    border: 2px rgb(100, 100, 100) solid;
}

.ttcButton2:hover {
    border: 2px orange solid;
    background: rgb(247, 166, 0);
}

.cryptoLogo {
    vertical-align: middle;
    margin-right: -9px !important;
}

.graphDialog {
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
    max-height: none !important;
    margin: auto !important;
}
.graphDialog * .MuiDialogTitle-root * .ttcButton{
    margin:10px !important;
}

.graphDialog * .css-ypiqx9-MuiDialogContent-root {
    padding: 0px !important;
}

.graphDialog * .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding-bottom: 0px !important;
}

.graphDialog * .css-bdhsul-MuiTypography-root-MuiDialogTitle-root .ttcButton{
    margin:3px !important;
}

.MuiRadio-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.MuiButtonBase-root.iconWarning SVG PATH {
    fill: red !important;
}

.MuiButtonBase-root:hover SVG PATH {
    fill: orange;
}
@import "../../../theme.scss";
.imgInsta {
  max-width: 100%;
}
.Feed {
  padding-bottom: 70px;
}
.Feed a img {
  margin-right: 15px;
  padding-bottom: 15px;
}


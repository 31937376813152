@import "../../../theme.scss";

@media (max-width: 400px) {
  .titleHeader{
    position: absolute;
    top:-90px !important;
    font-size:12px !important;
    line-height:15px !important;
  }
  .tabList{
    position: relative !important;
    padding-left:0px !important;
    margin-bottom: 0px !important;
    width: 100% !important;
  }

  .tabList * .iconWithSub * I.large.icon::before{
    font-size: 20px !important;
  }
  .react-tabs__tab{
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .tabList * .iconWithSub SPAN{
    font-size: 8px !important;
  }
  LI.react-tabs__tab.tab.titleHeader {
    display:block !important;
  }
} 

.comic {
  display: flex;
  flex-wrap: wrap;
  font-family: cursive;
}

.comicsPage-title.top-right * iframe[id^="twitter-widget-"] {
  width: 70px !important;
}

span.large i {
  font-weight: 700;
}

a.comic {
  display: inline;
}

.comicsPage {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-family: cursive;
  overflow: hidden !important;
  position: relative;
  /*padding-top: 40px;*/
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.comicsPage.transparent {
  background-image: none !important;
  border: 2px solid rgba(0, 0, 0, 0) !important;
}

.comicsPage.collapsed {
  height: 50px !important;
  display: block;
  overflow: hidden !important;
}

.comicsPage.collapsed * div {
  display: none !important;
}

.comicsPage-titleNoLink {
  cursor: default !important;
  color: $TITLENOLINK !important;
}

.row.comicsPage {
  background: rgb(90, 90, 90);
  border: 2px solid black;
  font-family: cursive;
  margin-top: 30px !important;
}

.row.comicsPage * label {
  color: #fdeca6;
}

.comicsPage.fullPage > div {
  width: 100%;
}

.comicsCell {
  overflow: hidden;
}

.comicsCellFullWidth div.panel {
  width: 100% !important;
  min-height: 200px;
}

.comicsCellTitle div.top-left {
  background: #69a4b5;
}

.comicsPage-title {
  background-color: $COMICS_PAGE_TITLE !important;
}
.comicsPage-title a {
  color: rgb(255, 255, 255) !important;
}

.comicsPage-title.text.page-top.top-left a {
  color: rgb(255, 255, 255);
  font-size: 16px;
}

p.page-top,
div.page-top {
  background-color: white;
  position: absolute;
}

.panel {
  background-color: $PANEL_BACKGROUND;
  border: solid 2px #000;
  display: inline-block;
  margin: 5px;
  padding: 10px;
  position: relative;
}

.text {
  background-color: #fff;
  border: solid 2px #000;
  margin: 0;
  padding: 3px 10px;
}

.text2 {
  background-color: #fff;
  border: 0px !important;
  margin: 0;
  padding: 3px 10px;
}

i.certified {
  color: rgb(30, 112, 191);
}

.tooltipvalidated {
 // background-image: radial-gradient(circle, #ffe100, orange) !important;
  margin: 2px;
}

.tooltipvalidated i.certified {
  font-size: 30px !important;
}
.tooltipvalidated i.certified:before {
  color:lightgrey;
}

.top-left {
  left: -6px;
  position: absolute;
  top: 0px;
  width:100%;
 /* transform: skew(-15deg);*/
 border:0px;
 background: transparent !important;
}

.top-right {
  right: -6px;
  position: absolute;
  top: -2px;
  transform: skew(-15deg);
}

.bottom-left {
  left: -6px;
  position: absolute;
 /* bottom: -2px;
  transform: skew(-15deg);
  max-width: 70%;*/
  background: transparent;
}
.bottom-left * span {
  font-size: 12px !important;
  font-weight:normal !important;
  color: darkgrey;
}

.content {
  position: relative;
  left: 5px;
  top: 55px;
  width: 100%;
  padding-top: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.bottom-right {
  bottom: -2px;
  position: absolute;
  right: -6px;
  transform: skew(-15deg);
}

.bottom-right2 {
  bottom: 26px;
  position: absolute;
  right: -6px;
  transform: skew(-15deg);
}

.speech {
  background-color: #fff;
  border: solid 2px #000;
  border-radius: 12px;
  display: inline-block;
  padding: 0.5em 1em;
  margin: 5px !important;
  position: relative;
}

.speech:before {
  border: solid 12px transparent;
  border-left: solid 12px #000;
  border-top: solid 12px #000;
  bottom: -24px;
  content: "";
  height: 0;
  left: 24px;
  position: absolute;
  transform: skew(-15deg);
  width: 0;
}

.speech:after {
  border: solid 10px transparent;
  border-left: solid 10px #fff;
  border-top: solid 10px #fff;
  bottom: -19px;
  content: "";
  height: 0;
  left: 27px;
  position: absolute;
  transform: skew(-15deg);
  width: 0;
}
/************* START BUTTON ************/

.comicsButton-href {
  background: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

/************* END BUTTON ************/

.titleHeader {
  color: white !important;
  background:red !important;
}

.tabList{
  position: fixed;
  top: 0px !important;
  left: 0px !important;
  border: 0px;
  padding-left:230px;
  margin-bottom: 0px !important;
  width: calc(100% - 300px);
  z-index:1000 !important;
}

.tabList * .twitter,.tabList * .twitch{
  background: transparent !important;
}

.tabList * .react-tabs__tab-panel{
  z-index:999 !important;

}


.react-tabs .comicsPage {
  margin-left: 0px !important;
  padding-top: 10px !important;
}

.tabList .tab,
.tabList .selectedTab {
  font-family: "Bangers" !important;
  font-size: 20px;
  line-height: 35px;
}

.tabList .selectedTab {
  background: rgba(255, 255,255, 0.5) !important;
}
.tabList .selectedTab SPAN.iconWithSub SPAN{
  color:black;
}

.tabList .tab {
  color: $TABLIST_TAB;
}
.tabList .tab:hover {
  color: rgb(65, 131, 196);
}

.tabList .tab.selectedTab span i.red.icon {
  background: transparent !important;
  color: orange !important;
}

.tabList .tab {
  margin: 2px;
 background: transparent;
 border: 0px;
}
.tabList .tab:first-child {
  border: 2px solid rgba(0, 0, 0, 0);
  margin: 2px;
  background: rgba(0, 0, 0, 0);
}

.tabList .tab span i.red.icon {
  background: transparent !important;
  color: #e6e6e6 !important;
}
.tabList .tab span i.red.icon:hover {
  background: transparent !important;
  color: rgb(65, 131, 196) !important;
}
.tabList * .rss{
  background:none !important;
}

.modernCCPanel {
  overflow: hidden !important;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.panel.modernCCPanel {
  margin-top: 0px !important;
  border: 0px;
  background: transparent;
  box-shadow: none;
}

.modernCCPanel div.content {
  height: auto !important;
}

.modernCC {
  display: flex;
  align-items: stretch;
  height: auto !important;
  top: -38px;
  position: relative;
}
.modernCC-widget {
  width: 320px !important;
}
.modernCC-content {
  position: relative;
  left: 5px;
  top: 55px;
  padding-top: 0px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.geekiButton {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white !important;
  text-align: center;
  transition: all 0.2s;
  background: orange !important;
}
.geekiButton:hover {
  color: orange;
  background-color: rgb(35, 94, 111);
}
.geekiButton.add{
  background: green !important;
  width: 200px;
}
.geekiButton.remove{
  background: red !important;
  width: 200px;
}

.button2 {
  border-radius: 44px !important;
  border-width: 0px;
  background: rgb(35, 94, 111) !important;
  color: rgb(255, 255, 255) !important;
  font-family: proxima-nova;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 20px;
  transition: background 0.2s ease-in-out 0s;
  /* width: 100%;*/
  padding: 12px 24px;
  border: 0px;
  cursor: pointer;
  margin: 0px;
  outline: none;
  padding: 0px;
}

@media all and (max-width: 30em) {
  .geekiButton {
    display: block;
    margin: 0.4em auto;
  }
}

.rssPanel {
  min-height: 200px;
  overflow: hidden !important; 
  background: rgb(201, 194, 190);
}

.panel.rssPanel {
  margin-right: 100px !important;
  margin-top: 20px !important;
}

.rssPanel div.content {
  height: auto !important;
  margin-bottom: 60px;
}

.rssItem {
  display: flex;
  align-items: stretch;
  height: auto !important;
}

.rssItem-logo {
  height: auto !important;
  display: inline-block;
}

.rssItem-content {
  width: calc(100% - 300px) !important;
  height: auto !important;
  display: inline-block;
  margin-right: 10px;
  margin-left:10px;
}

.rssItem-content > div {
  width: 100% !important;
  height: auto;
  overflow-wrap: break-word !important;
  white-space: normal;
}

.rssItem-content * IMG{
 display: none;
}

.rssPanel .top-left{
  background-color:rgb(35, 94, 111) !important;
  left:0px !important;
  padding-bottom: 10px;
}
.rssPanel .top-left SPAN I{
  color:white !important;
}
